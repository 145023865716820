<template>
  <RealisasiVariableKpiKorporatForm mode="Ubah" module="Realisasi Variable Indikator Kinerja">
  </RealisasiVariableKpiKorporatForm>
</template>

<script>
import RealisasiVariableKpiKorporatForm from './form';

const RealisasiVariableKpiKorporatUpdate = {
  name: 'RealisasiVariableKpiKorporatUpdate',
  components: { RealisasiVariableKpiKorporatForm },
};

export default RealisasiVariableKpiKorporatUpdate;
</script>
