<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="variable_kpi_korporat_id" label="Variable Indikator Kinerja">
            <a-select
              placeholder="Pilih Variable Indikator Kinerja"
              v-model:value="formState.variable_kpi_korporat_id"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.variableKpiKorporat" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="month" label="Bulan">
            <a-select
              placeholder="Pilih Bulan"
              v-model:value="formState.month"
              show-search
              :filter-option="filterSelect"
            >
              <a-select-option v-for="option in options.month" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="realisasi_nominal" label="Realisasi Nominal">
            <a-input-number v-model:value="formState.realisasi_nominal" placeholder="Masukkan Realisasi Nominal" />
          </a-form-item>
          <a-form-item name="realisasi_waktu" label="Realisasi Waktu">
            <a-month-picker
              v-model:value="formState.realisasi_waktu"
              placeholder="Pilih Realisasi Waktu"
              format="MM-YYYY"
              valueFormat="YYYY-MM"
            />
          </a-form-item>
          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link to="/master/realisasi-variable-kpi-korporat">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const RealisasiVariableKpiKorporatForm = {
  name: 'RealisasiVariableKpiKorporatForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);
    const dateObj = new Date();

    const formState = reactive({
      variable_kpi_korporat_id: undefined,
      month: dateObj.getUTCMonth() + 1,
      realisasi_nominal: null,
      realisasi_waktu: '',
    });

    const options = reactive({
      variableKpiKorporat: [],
      month: [
        { value: 1, text: 'Januari' },
        { value: 2, text: 'Februari' },
        { value: 3, text: 'Maret' },
        { value: 4, text: 'April' },
        { value: 5, text: 'Mei' },
        { value: 6, text: 'Juni' },
        { value: 7, text: 'Juli' },
        { value: 8, text: 'Agustus' },
        { value: 9, text: 'September' },
        { value: 10, text: 'Oktober' },
        { value: 11, text: 'November' },
        { value: 12, text: 'Desember' },
      ],
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const handleSubmit = values => {
      if (values.realisasi_waktu) {
        let my = values.realisasi_waktu.split('-');
        values['realisasi_waktu'] = my[0] + '-' + my[1] + '-' + '01';
      }

      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'realisasi-variable-kpi-korporat',
          data: values,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.id,
          url: 'realisasi-variable-kpi-korporat',
          data: values,
        });
      }
    };

    const rules = {
      variable_kpi_korporat_id: [
        {
          required: true,
          message: 'Harap Dipilih',
          trigger: 'blur',
          type: 'number',
        },
      ],
      month: [
        {
          required: true,
          message: 'Harap Dipilih',
          trigger: 'blur',
          type: 'number',
        },
      ],
    };

    const filterSelect = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    onMounted(() => {
      dispatch('axiosCrudGetData', 'options-variable-kpi-korporat').then(() => {
        options.variableKpiKorporat = crud.value;
      });

      if (props.mode == 'Ubah') {
        const data = {
          url: 'realisasi-variable-kpi-korporat',
          id: params.id,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          Object.keys(crud.value).forEach(key => {
            formState[key] = crud.value[key];
          });
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      options,
      filterSelect,
    };
  },
};

export default RealisasiVariableKpiKorporatForm;
</script>
